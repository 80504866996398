import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import { firebaseApp } from 'src/libs/firebase';
import { Issuer } from 'src/utils/auth';

// const queryUserOrg = `
// query UserOrg($firebaseUid: String!) {
//   userOrg(firebaseUid: $firebaseUid) {
//     _id
//     firebaseUid
//     name
//     level
//     organization {
//       _id
//       orgName
//       timeZone
//       accessCode
//       smartCarApiKey
//       smartCarApiAccessToken
//       vehicleMaxLimit
//       defaultLat
//       defaultLong
//       defaultZoom
//       prefFullyCharged
//       prefAttentionCharge
//       chargers
//       fleet {
//         _id
//         vehicleName
//         vehicleGroup
//         vehicleType
//       }
//     }
//   }
// }
// `;

// const fetchPortFi = async (query, variables) => {
//   let fetchLink = '/graphql'; //CONFIRM at CLOUD

//   let fetchResult = {}
//   await fetch(fetchLink, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({
//       query,
//       variables
//     }),
//   })
//     .then((res) => res.json())
//     .then((result) => {
//       if (result && result.data) {
//         fetchResult = result.data;
//       } else {
//         console.log(`Info unavailable`);
//         fetchResult = {};
//       }
//     })
//     .catch((e) => {
//       console.log(`fetch error`);
//     });
//   return fetchResult
// }


const auth = getAuth(firebaseApp);

var ActionType;
(function (ActionType) {
  ActionType['AUTH_STATE_CHANGED'] = 'AUTH_STATE_CHANGED';
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;
    // if (user) {
    //   let extendedUserOrg = {}
    //   if (user.uid) { 
        
    //     extendedUserOrg = fetchPortFi(queryUserOrg, { firebaseUid: user.uid }) 
      
    //   }
    //   console.log(extendedUserOrg)
    //   // user.name = extendedUserOrg?.userOrg.name || 'PortFi Client';
    //   // user.plan = extendedUserOrg?.userOrg.level || '1';
    //   // user.organization = extendedUserOrg?.userOrg.organization || null

    //   console.log(user)
    // }

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.Firebase,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAuthStateChanged = useCallback(async (user) => {
    if (user) {
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      dispatch({
        type: ActionType.AUTH_STATE_CHANGED,
        payload: {
          isAuthenticated: true,
          user: {
            id: user.uid,
            avatar: user.photoURL || undefined,
            email: user.email || undefined,
          }
        }
      });
    } else {
      dispatch({
        type: ActionType.AUTH_STATE_CHANGED,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }, [dispatch]);

  useEffect(() => onAuthStateChanged(auth, handleAuthStateChanged),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const _signInWithEmailAndPassword = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  }, []);

  const signInWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider);
  }, []);

  const _createUserWithEmailAndPassword = useCallback(async (email, password) => {
    const createUserWithEmailAndPasswordRes = await createUserWithEmailAndPassword(auth, email, password);
    if (createUserWithEmailAndPasswordRes) {
      return createUserWithEmailAndPasswordRes.user.uid
    }
  }, []);

  const _signOut = useCallback(async () => {
    await signOut(auth);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.Firebase,
        createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        signInWithGoogle,
        signOut: _signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
