import PropTypes from 'prop-types';

import { withAuthGuard } from 'src/hocs/with-auth-guard';
import { useSettings } from 'src/hooks/use-settings';

// import { useSections } from './config';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';

import { useAuth } from 'src/hooks/use-auth';

import { useQuery } from '@apollo/client';
import { QUERY_EXTENDEDUSERORG } from '../../utils/queries';
// import { useRouter } from 'src/hooks/use-router';
// import { paths } from 'src/paths';
import { SplashScreen } from 'src/components/splash-screen';


//imports for config
// import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';

// import AlignLeft02Icon from 'src/icons/untitled-ui/duocolor/align-left-02';
// import BarChartSquare02Icon from 'src/icons/untitled-ui/duocolor/bar-chart-square-02';
import Building04Icon from 'src/icons/untitled-ui/duocolor/building-04';
// import CalendarIcon from 'src/icons/untitled-ui/duocolor/calendar';
// import CheckDone01Icon from 'src/icons/untitled-ui/duocolor/check-done-01';
// import CreditCard01Icon from 'src/icons/untitled-ui/duocolor/credit-card-01';
// import CurrencyBitcoinCircleIcon from 'src/icons/untitled-ui/duocolor/currency-bitcoin-circle';
// import File01Icon from 'src/icons/untitled-ui/duocolor/file-01';
// import GraduationHat01Icon from 'src/icons/untitled-ui/duocolor/graduation-hat-01';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import LayoutAlt02Icon from 'src/icons/untitled-ui/duocolor/layout-alt-02';
import LineChartUp04Icon from 'src/icons/untitled-ui/duocolor/line-chart-up-04';
// import Lock01Icon from 'src/icons/untitled-ui/duocolor/lock-01';
// import LogOut01Icon from 'src/icons/untitled-ui/duocolor/log-out-01';
// import Mail03Icon from 'src/icons/untitled-ui/duocolor/mail-03';
// import Mail04Icon from 'src/icons/untitled-ui/duocolor/mail-04';
// import MessageChatSquareIcon from 'src/icons/untitled-ui/duocolor/message-chat-square';
// import ReceiptCheckIcon from 'src/icons/untitled-ui/duocolor/receipt-check';
// import Share07Icon from 'src/icons/untitled-ui/duocolor/share-07';
// import ShoppingBag03Icon from 'src/icons/untitled-ui/duocolor/shopping-bag-03';
// import ShoppingCart01Icon from 'src/icons/untitled-ui/duocolor/shopping-cart-01';
import Truck01Icon from 'src/icons/untitled-ui/duocolor/truck-01';
import Upload04Icon from 'src/icons/untitled-ui/duocolor/upload-04';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
// import XSquareIcon from 'src/icons/untitled-ui/duocolor/x-square';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';

const inlineConfig = (t, user) => {
  if (!user?.dashboardConfig) { return [] }
  const { showStations11, showStations31, showUsers11, showUsers31, showEnergy11, showEnergyHistory11,
    showEnergyLoad11, showEnergy21, showEnergy31, showEnergyHistory31, showEnergyLoad31,
    showVehicles11, showLocations11, showAccount11, showAccount21, showAccount31 } = user?.dashboardConfig
  // console.log(user?.dashboardConfig)
  let items = []
  items.push({
    title: t(tokens.nav.dashboard),
    path: paths.index,
    icon: (
      <SvgIcon fontSize="small">
        <LayoutAlt02Icon />
      </SvgIcon>
    ),
  })
  // if (showDashboard11) { }
  // if (showDashboard21) { }
  // if (showDashboard31) { }
  if (showStations11) {
    items.push({
      title: t(tokens.nav.stations),
      path: paths.dashboard.stations.index,
      icon: (
        <SvgIcon fontSize="small">
          <Building04Icon />
        </SvgIcon>
      ),
    })
  }
  if (showStations31) { }
  if (showUsers11) {
    items.push({
      title: t(tokens.nav.tagusers),
      path: paths.dashboard.tagusers.index,
      icon: (
        <SvgIcon fontSize="small">
          <Users03Icon />
        </SvgIcon>
      ),
    })
  }
  if (showUsers31) {
    items.push({
      title: t(tokens.nav.payers),
      path: paths.dashboard.payers.index,
      icon: (
        <SvgIcon fontSize="small">
          <Users03Icon />
        </SvgIcon>
      ),
    })
  }
  if (showEnergy11) {
    let subitems = []
    if (showEnergyHistory11) {
      subitems.push({
        title: t(tokens.nav.history),
        path: paths.dashboard.energy.history
      })
    }
    if (showEnergyLoad11) {
      subitems.push({
        title: t(tokens.nav.load),
        path: paths.dashboard.energy.load
      })
    }
    items.push({
      title: t(tokens.nav.energy),
      path: paths.dashboard.energy.index,
      icon: (
        <SvgIcon fontSize="small">
          <LineChartUp04Icon />
        </SvgIcon>
      ),
      items: subitems,
    })
  }

  if (showEnergy21) { }
  if (showEnergy31) {
    if (showEnergyHistory31) { }
    if (showEnergyLoad31) { }
  }
  if (showEnergyHistory31) { }
  if (showEnergyLoad31) { }
  if (showVehicles11) {
    items.push({
      title: t(tokens.nav.vehicles),
      path: paths.dashboard.vehicles.index,
      icon: (
        <SvgIcon fontSize="small">
          <Truck01Icon />
        </SvgIcon>
      ),
    })
  }
  if (showLocations11) {
    items.push({
      title: t(tokens.nav.locations),
      path: paths.dashboard.locations,
      icon: (
        <SvgIcon fontSize="small">
          <Upload04Icon />
        </SvgIcon>
      ),
    })
  }
  if (showAccount11) {
    items.push({
      title: t(tokens.nav.account),
      path: paths.dashboard.account,
      icon: (
        <SvgIcon fontSize="small">
          <HomeSmileIcon />
        </SvgIcon>
      ),
    })
  }
  if (showAccount21) { }
  if (showAccount31) { }
  return [{ items }]
}

export const Layout = withAuthGuard((props) => {
  const settings = useSettings();
  // const sections = useSections();
  // const router = useRouter();
  const { t } = useTranslation();

  const { user } = useAuth();
  // if (user && user.id) {
  const { loading, error, data } = useQuery(QUERY_EXTENDEDUSERORG, { variables: { firebaseUid: user?.id } });
  // if (loading) return <p>...</p>;
  if (loading) return <SplashScreen />;
  if (error) return <p>Error : {error.message}</p>;
  user.name = data?.userOrg?.name || 'PortFi Client';
  user.plan = data?.userOrg?.level || '1';
  user.organization = data?.userOrg?.organization || null
  user.dashboardConfig = data?.userOrg?.dashboardConfig || {}

  // }
  const sections = inlineConfig(t, user)

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        // user={user}
        sections={sections}
        navColor={settings.navColor}
        {...props} />
    );
  }

  return (
    <VerticalLayout
      // user={user}
      sections={sections}
      navColor={settings.navColor}
      {...props} />
  );
});

Layout.propTypes = {
  children: PropTypes.node
};
