import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';

const LocationsPage = lazy(() => import('src/pages/dashboard/vehicles/locations'));
const StationsPage = lazy(() => import('src/pages/dashboard/stations/list'));
const StationsCreatePage = lazy(() => import('src/pages/dashboard/stations/create'));
const VehiclesPage = lazy(() => import('src/pages/dashboard/vehicles/list'));
// const VehiclesCreatePage = lazy(() => import('src/pages/dashboard/vehicles/create'));

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// Academy
const AcademyDashboardPage = lazy(() => import('src/pages/dashboard/academy/dashboard'));
const AcademyCoursePage = lazy(() => import('src/pages/dashboard/academy/course'));

// Blog
const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));
const BlogPostCreatePage = lazy(() => import('src/pages/dashboard/blog/create'));

// Customer
const CustomerListPage = lazy(() => import('src/pages/dashboard/customers/list'));
const CustomerDetailPage = lazy(() => import('src/pages/dashboard/customers/detail'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customers/edit'));

// Tag Users
const TagUserListPage = lazy(() => import('src/pages/dashboard/tagusers/list'));
const TagUserDetailPage = lazy(() => import('src/pages/dashboard/tagusers/detail'));
const TagUserEditPage = lazy(() => import('src/pages/dashboard/tagusers/edit'));

// Payers
const PayerListPage = lazy(() => import('src/pages/dashboard/payers/list'));
const PayerDetailPage = lazy(() => import('src/pages/dashboard/payers/detail'));
const PayerEditPage = lazy(() => import('src/pages/dashboard/payers/edit'));

// Visitors
const VisitorsPage = lazy(() => import('src/pages/dashboard/visitors/detail'));

// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

// Job
const JobBrowsePage = lazy(() => import('src/pages/dashboard/jobs/browse'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/jobs/create'));
const CompanyDetailPage = lazy(() => import('src/pages/dashboard/jobs/companies/detail'));

// Logistics
const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard/logistics/dashboard'));
const LogisticsFleetPage = lazy(() => import('src/pages/dashboard/logistics/fleet'));

// Order
const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));

// Product
const ProductListPage = lazy(() => import('src/pages/dashboard/products/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/products/create'));

// Social
const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const EnergyHistoryPage = lazy(() => import('src/pages/dashboard/energy/history'));
const EnergyLoadPage = lazy(() => import('src/pages/dashboard/energy/load'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const CryptoPage = lazy(() => import('src/pages/dashboard/crypto'));
const EcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: 'locations',
        children: [
          {
            index: true,
            element: <LocationsPage />
          }
        ]
      },
      {
        path: 'stations',
        children: [
          {
            index: true,
            element: <StationsPage />
          },
          {
            path: 'create',
            element: <StationsCreatePage />
          }
        ]
      },
      {
        path: 'tagusers',
        children: [
          {
            index: true,
            element: <TagUserListPage />
          },
          {
            path: ':tagUserId',
            element: <TagUserDetailPage />
          },
          {
            path: ':tagUserId/edit',
            element: <TagUserEditPage />
          }
        ]
      },
      {
        path: 'payers',
        children: [
          {
            index: true,
            element: <PayerListPage />
          },
          {
            path: ':payerId',
            element: <PayerDetailPage />
          },
          {
            path: ':payerId/edit',
            element: <PayerEditPage />
          }
        ]
      },
      {
        path: 'visitors',
        children: [
          {
            index: true,
            element: <VisitorsPage />
          }
        ]
      },
      {
        path: 'vehicles',
        children: [
          {
            index: true,
            element: <VehiclesPage />
          },
          // {
          //   path: 'create',
          //   element: <VehiclesCreatePage />
          // }
        ]
      },


      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'academy',
        children: [
          {
            index: true,
            element: <AcademyDashboardPage />
          },
          {
            path: 'courses',
            children: [
              {
                path: ':courseId',
                element: <AcademyCoursePage />
              }
            ]
          }
        ]
      },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: <BlogPostListPage />
          },
          {
            path: 'create',
            element: <BlogPostCreatePage />
          },
          {
            path: ':postId',
            element: <BlogPostDetailPage />
          }
        ]
      },
      {
        path: 'customers',
        children: [
          {
            index: true,
            element: <CustomerListPage />
          },
          {
            path: ':customerId',
            element: <CustomerDetailPage />
          },
          {
            path: ':customerId/edit',
            element: <CustomerEditPage />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          }
        ]
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <JobBrowsePage />
          },
          {
            path: 'create',
            element: <JobCreatePage />
          },
          {
            path: 'companies',
            children: [
              {
                path: ':companyId',
                element: <CompanyDetailPage />
              }
            ]
          }
        ]
      },
      {
        path: 'logistics',
        children: [
          {
            index: true,
            element: <LogisticsDashboardPage />
          },
          {
            path: 'fleet',
            element: <LogisticsFleetPage />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <OrderListPage />
          },
          {
            path: ':orderId',
            element: <OrderDetailPage />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            index: true,
            element: <ProductListPage />
          },
          {
            path: 'create',
            element: <ProductCreatePage />
          }
        ]
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeedPage />
          },
          {
            path: 'profile',
            element: <SocialProfilePage />
          }
        ]
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />
      },
      {
        path: 'energy',
        children: [
          {
            index: true,
            element: <EnergyHistoryPage />
          },
          {
            path: 'history',
            element: <EnergyHistoryPage />
          },
          {
            path: 'load',
            element: <EnergyLoadPage />
          }
        ]
      },
      {
        path: 'blank',
        element: <BlankPage />
      },
      {
        path: 'calendar',
        element: <CalendarPage />
      },
      {
        path: 'chat',
        element: <ChatPage />
      },
      {
        path: 'crypto',
        element: <CryptoPage />
      },
      {
        path: 'ecommerce',
        element: <EcommercePage />
      },
      {
        path: 'file-manager',
        element: <FileManagerPage />
      },
      {
        path: 'kanban',
        element: <KanbanPage />
      },
      {
        path: 'mail',
        element: <MailPage />
      }
    ]
  }
];
